import React, {useContext} from 'react'
import styled from 'styled-components'
import GradientWithTitleAndSubtitle from 'los-design-system/components/GradientWithTitleAndSubtitle'
import Button from 'los-design-system/components/Button'
import Context from '../context/modalContext'

const ButtonStyle = styled(Button)`
  @media (min-width: 768px) {
    margin-top: 16px;
  }
`

const CallToAction = () => {
  const {onOpen} = useContext(Context)

  return (
    <GradientWithTitleAndSubtitle
      subtitle="Get your Free Copy of the Ultimate Guide"
      title="Start building your digital signage success story on public screens."
    >
      <ButtonStyle
        text="SEND ME THE BOOK"
        theme="yellow"
        size="large"
        type="submit"
        onClick={onOpen}
      />
    </GradientWithTitleAndSubtitle>
  )
}

export default CallToAction
