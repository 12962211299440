/* eslint-disable camelcase */
import React from 'react'
import styled from 'styled-components'
import {graphql, navigate} from 'gatsby'
import {ThemeProvider, ResetCss, Footer, Container, Box, Grid} from 'los-design-system'
import Article from 'los-design-system/components/Article'
import ArticleSocialShare from 'los-design-system/components/ArticleSocialShare'
import ArticleContainer from 'los-design-system/components/ArticleContainer'
import Navigation from '../components/Navigation'
import CallToAction from '../components/CallToAction'
import ChaptersList from '../components/ChaptersList'
import Modal from '../components/Modal'
import ArticlePagination from '../components/ArticlePagination'
import Seo from '../components/Seo'

const WrapperStyle = styled('div')`
  padding-bottom: 85px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -15px;
    right: -15px;
    bottom: 0;
    height: 85px;
    width: calc(100% + 30px);
    background-size: 9px 18px;
    background-image: url('/media/dots-large.svg');
  }

  @media (min-width: 992px) {
    &:before {
      left: 0;
      right: 0;
      width: 100%;
    }
  }
`

const TitleStyle = styled('h1')`
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.31;
  text-align: center;
  color: #343132;
  margin: 32px 0;
  max-width: 950px;

  @media (min-width: 992px) {
    font-size: 44px;
    margin: 91px auto 32px;
  }
`

const SubTitleStyle = styled('h1')`
  font-family: Montserrat, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.65;
  text-align: center;
  color: #929598;
  margin-bottom: 56px;
`

const StyledArticle = styled(Article)`
  font-family: Montserrat, sans-serif;
  ul {
    li {
      &:before {
        content: '●';
        margin-right: 17px;
      }
      display: flex;
      white-space: pre-wrap;
      p {
        margin-left: 0;
      }
    }
  }
  img {
    margin-top: 0;
  }
  figcaption {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.64;
    color: rgb(146, 149, 152);
    margin-top: 8px;
  }
`

export const query = graphql`
  query BlogPostByID($slug: String) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      html
      frontmatter {
        title
        subtitle
        description
        chapter
      }
      fields {
        slug
      }
    }
    allMarkdownRemark(sort: {fields: frontmatter___chapter, order: ASC}) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          chapter
        }
      }
    }
  }
`

interface PostProps {
  path: string
  navigate(to: string): void
  data: {
    markdownRemark: {
      frontmatter: {
        title: string
        subtitle: string
        description: string
        chapter: number
      }
      fields: {
        slug: string
      }
      html: string
    }
    allMarkdownRemark: {
      nodes: {
        fields: {
          slug: string
        }
        frontmatter: {
          title: string
          chapter: number
        }
      }[]
    }
  }
}

export default ({data, path}: PostProps) => {
  const {
    markdownRemark: post,
    allMarkdownRemark: {nodes: posts},
  } = data

  return (
    <ThemeProvider theme={{}}>
      <ResetCss />
      <Seo
        article
        author="LifeOnScreen"
        coverImage="https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_mobile-min.png"
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        postPath={post.fields.slug}
      />
      <Navigation pathname={path} />
      <Container>
        <WrapperStyle>
          <TitleStyle>{post.frontmatter.title}</TitleStyle>
          <SubTitleStyle>{post.frontmatter.subtitle}</SubTitleStyle>
        </WrapperStyle>
      </Container>
      {posts && posts.length > 2 && <ChaptersList posts={posts} path={path} />}
      <ArticleContainer>
        <Box position="relative">
          <Grid gridTemplateColumns={[null, null, null, '0px auto']}>
            <ArticleSocialShare
              url={`https://digital-signage.lifeonscreen.com${post.fields.slug}`}
              title={post.frontmatter.title}
              description={post.frontmatter.description}
              twitterTitle={post.frontmatter.title}
            />
            <div>
              <br />
              <StyledArticle
                dangerouslySetInnerHTML={{
                  __html: post.html,
                }}
              />
            </div>
          </Grid>
        </Box>
      </ArticleContainer>
      {posts && posts.length > 2 && (
        <ArticlePagination posts={posts} chapter={post.frontmatter.chapter} />
      )}
      <CallToAction />
      <Footer />
      <Modal navigate={navigate} />
    </ThemeProvider>
  )
}
