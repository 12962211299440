import React from 'react'

interface DownloadIconProps {
  width: string
  height: string
  color: string
}

function DownloadIcon({height, width, color}: DownloadIconProps) {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M8.57 5.88a.53.53 0 0 0-.77 0L6.55 7.17V.57A.56.56 0 0 0 6 0c-.3 0-.55.26-.55.57v6.65L4.2 5.9a.53.53 0 0 0-.74.03.6.6 0 0 0-.03.77l2.18 2.29c.1.1.25.16.39.16h.03c.18 0 .34-.1.44-.25l2.1-2.2c.1-.1.16-.25.16-.4 0-.16-.06-.3-.16-.41z" />
        <rect transform="rotate(180 6 11.43)" y="10.86" width="12" height="1.14" rx=".57" />
      </g>
    </svg>
  )
}

export default DownloadIcon
