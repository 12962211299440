import React, {useContext} from 'react'
import gql from 'graphql-tag'
import {Mutation, MutationResult} from 'react-apollo'
import styled from 'styled-components'
import {Modal, Input, Button} from 'los-design-system'
import {FORM_ERROR} from 'final-form'
import {Form, Field} from 'react-final-form'
import Checkbox from './Checkbox'
import Context from '../context/modalContext'

interface GraphQlError {
  graphQLErrors?: ({
    message: string
    exception?: {
      code: string
      meta?: Record<string, string>
    }
    validation?: Record<string, string[]>
  })[]
}

function handleGraphQlSubmissionErrors(errors: GraphQlError): Record<string, string> | null {
  if (errors && errors.graphQLErrors && errors.graphQLErrors.length > 0) {
    // Get general error message
    const message = errors.graphQLErrors.map((e: {message: string}) => e.message).join(' ')

    // Form validation error messages
    const validationObj = errors.graphQLErrors.reduce((obj, graphQLError) => {
      if (graphQLError.validation) {
        const tmpError = Object.keys(graphQLError.validation).reduce(
          (acc, currentValue) => ({
            ...acc,
            [currentValue]:
              graphQLError.validation &&
              Array.isArray(graphQLError.validation[currentValue]) &&
              graphQLError.validation[currentValue].join(' '),
          }),
          {},
        )

        return {
          ...obj,
          ...tmpError,
        }
      }

      if (graphQLError.exception && graphQLError.exception.meta) {
        const tmpError = Object.keys(graphQLError.exception.meta).reduce(
          (acc, currentValue) => ({
            ...acc,
            // @ts-ignore
            [currentValue]: graphQLError.exception.meta[currentValue],
          }),
          {},
        )

        return {
          ...obj,
          ...tmpError,
        }
      }

      return obj
    }, {})

    return {
      ...(Object.keys(validationObj).length > 0
        ? {}
        : {
            [FORM_ERROR]: message,
            general: message,
          }),
      ...validationObj,
    }
  }

  return null
}

const requiredValidation = (value: unknown) => {
  if ((value && !Array.isArray(value)) || (Array.isArray(value) && value.length > 0)) {
    return undefined
  }

  return 'Required'
}

const emailValidation = (value?: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

const composeValidators = (...validators: any) => (value: any) =>
  validators.reduce((error: any, validator: any) => error || validator(value), undefined)

const normalizePhoneNumber = (value?: string) => {
  if (!value) {
    return value
  }

  let phoneNumber = value.replace(/[^\d+ -]+/g, '')
  phoneNumber = phoneNumber.replace(/^00/, '+')
  return phoneNumber
}

const NameSurnameWrapperStyle = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 9px;
`

const ButtonWrapperStyle = styled('div')`
  margin: 14px auto 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const LinkStyle = styled('a')`
  color: #00aeef;
`

const Wrapper = styled('div')`
  padding 64px 32px 16px;
`

const CheckboxWrapper = styled('div')`
  white-space: pre-wrap;
  padding-top: 2px;
`

const Error = styled('div')`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  color: #ee2f53;
  margin-bottom: 16px;
`

const FORM_MUTATION = gql`
  mutation DigitalSignageFormMutation(
    $first_name: String!
    $last_name: String!
    $email: String!
    $company: String!
    $job_title: String!
    $phone_number: String
  ) {
    landingPageCreateLead(
      first_name: $first_name
      last_name: $last_name
      email: $email
      company: $company
      job_title: $job_title
      phone_number: $phone_number
    ) {
      id
    }
  }
`

interface DoohModalInterface {
  navigate(to: string): void
}

const DoohModal = ({navigate}: DoohModalInterface) => {
  const {showModal, onClose} = useContext(Context)

  return (
    <Modal
      visible={showModal}
      onClose={onClose}
      title="One small gift from us one giant leap for your campaign."
      titleVariant="center"
    >
      <Mutation mutation={FORM_MUTATION}>
        {(submitForm: any, {error, loading}: MutationResult) => {
          return (
            <Wrapper>
              {error &&
                error.graphQLErrors &&
                error.graphQLErrors.map(singleError => (
                  <Error key={singleError.message}>{singleError.message}</Error>
                ))}
              <Form
                onSubmit={values => {
                  submitForm({
                    variables: values,
                  })
                    .then(() => {
                      navigate('/mailbox/thank-you')
                      // window.dataLayer.push({ event: 'ug-ebook-2018-form-success' });
                      //   postEventsToLambda({
                      //     eventName: events.DOOH_BOOK_DOWNLOAD,
                      //     eventData: {
                      //       // eslint-disable-next-line
                      //       n: values.first_name,
                      //       // eslint-disable-next-line
                      //       s: values.last_name,
                      //       e: values.email,
                      //       l: values.company,
                      //       // eslint-disable-next-line
                      //       j: values.job_title,
                      //       // eslint-disable-next-line
                      //       p: values.phone_number,
                      //     }
                      //   });
                      return null
                    })
                    .catch((errors: any) => handleGraphQlSubmissionErrors(errors))
                }}
                initialValues={{terms: false}}
                render={({handleSubmit}) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <NameSurnameWrapperStyle>
                        <Field
                          component={Input}
                          name="first_name"
                          placeholder="First Name"
                          validate={requiredValidation}
                        />
                        <Field
                          component={Input}
                          name="last_name"
                          placeholder="Last Name"
                          validate={requiredValidation}
                        />
                      </NameSurnameWrapperStyle>
                      <Field
                        component={Input}
                        name="email"
                        placeholder="Work Email Address"
                        validate={composeValidators(emailValidation, requiredValidation)}
                      />
                      <Field
                        component={Input}
                        name="company"
                        placeholder="Company"
                        validate={requiredValidation}
                      />
                      <Field
                        component={Input}
                        name="job_title"
                        placeholder="Job Title"
                        validate={requiredValidation}
                      />
                      <Field
                        component={Input}
                        name="phone_number"
                        placeholder="Phone Number (optional)"
                        parse={normalizePhoneNumber}
                      />
                      <Field
                        name="terms"
                        component={Checkbox}
                        type="checkbox"
                        validate={requiredValidation}
                        label={
                          <CheckboxWrapper>
                            I agree with the{' '}
                            <LinkStyle
                              href="https://lifeonscreen.com/terms-and-conditions"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms & Conditions.
                            </LinkStyle>
                          </CheckboxWrapper>
                        }
                      />
                      <ButtonWrapperStyle>
                        <Button text="DOWNLOAD" size="large" type="submit" loading={loading} />
                      </ButtonWrapperStyle>
                    </form>
                  )
                }}
              />
            </Wrapper>
          )
        }}
      </Mutation>
    </Modal>
  )
}

export default DoohModal
