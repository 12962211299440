import React, {useState, useContext} from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import Container from 'los-design-system/components/Container'
import Panel from 'los-design-system/components/Panel'
import Button from 'los-design-system/components/Button'
import CaretIcon from 'los-design-system/components/icons/CaretIcon'
import ArrowIcon from 'los-design-system/components/icons/ArrowIcon'
import DownloadIcon from './DownloadIcon'
import Context from '../context/modalContext'
import GetChapter from '../utils/GetChapter'

const WrapperStyle = styled('div')`
  background-color: #00aeef;
  min-height: 79px;
  padding: 24px 0;
  cursor: pointer;
`

const GridStyle = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 190px auto 190px;
    align-items: flex-start;
  }
`

const NextPrevLinkStyle = styled(Link)`
  font-family: Montserrat;
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }

  @media (min-width: 992px) {
    padding-top: 4px;
  }
`

const LinkInnerStyle = styled('div')`
  display: flex;
  align-items: center;
`

const BackIconNavStyle = styled(CaretIcon)`
  transform: rotate(90deg);
`

const NextIconNavStyle = styled(CaretIcon)`
  transform: rotate(-90deg);
`

const BackText = styled('span')`
  margin-left: 16px;
`

const NextText = styled('span')`
  margin-right: 16px;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
`

const LinkNextInnerStyle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ChapterListTextWrapperStyle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;

  @media (min-width: 992px) {
    padding: 4px 0;
  }
`

const ChapterListWrapperStyle = styled('div')`
  padding: 0 32px;
`

const ChapterListStyle = styled('ul')`
  list-style-type: none;
  padding: 32px 0 0;
  margin: 0 0 32px;
`

const LiStyle = styled('li')`
  margin-bottom: 8px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  color: #ffffff;
  display: flex;
  align-items: center;

  a {
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }
`

const NextIconStyle = styled(ArrowIcon)`
  position: relative;
  display: none;
  margin-right: 16px;

  @media (min-width: 992px) {
    display: block;
  }
`

interface ChaptersListProps {
  path: string
  posts: {
    fields: {
      slug: string
    }
    frontmatter: {
      title: string
      chapter: number
    }
  }[]
}

const ChaptersList = ({posts, path}: ChaptersListProps) => {
  const {onOpen} = useContext(Context)
  const [expandPanel, setExpandPanel] = useState(false)

  const index = posts.findIndex(p => {
    return p.fields.slug === path
  })

  const canGoBack = index !== 0
  const canGoForward = index !== posts.length - 1

  const backLink =
    canGoBack && typeof posts[index - 1] === 'object' ? posts[index - 1].fields.slug : '/'
  const backText =
    canGoBack && typeof posts[index - 1] === 'object'
      ? GetChapter(posts[index - 1].frontmatter.chapter)
      : 'Table of Contents'

  const nextLink =
    canGoForward && typeof posts[index + 1] === 'object' ? posts[index + 1].fields.slug : '/'
  const nextText =
    canGoForward && typeof posts[index + 1] === 'object'
      ? GetChapter(posts[index + 1].frontmatter.chapter)
      : 'Table of Contents'

  return (
    <WrapperStyle>
      <Container>
        <GridStyle>
          <NextPrevLinkStyle to={backLink}>
            <LinkInnerStyle>
              <BackIconNavStyle color="#fff" width="15px" height="15px" />
              <BackText>{backText}</BackText>
            </LinkInnerStyle>
          </NextPrevLinkStyle>
          <div>
            <ChapterListTextWrapperStyle onClick={() => setExpandPanel(!expandPanel)}>
              <NextText>Jump to Chapter</NextText>
              <CaretIcon color="#fff" width="15px" height="15px" />
            </ChapterListTextWrapperStyle>
            <Panel expanded={expandPanel}>
              <ChapterListWrapperStyle>
                <ChapterListStyle>
                  {posts.map(post => (
                    <LiStyle key={post.fields.slug}>
                      {path === post.fields.slug && (
                        <NextIconStyle direction="right" width="9px" height="15px" color="#fff" />
                      )}
                      <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                    </LiStyle>
                  ))}
                </ChapterListStyle>
                <Button
                  text="Download ebook"
                  size="large"
                  textAlign="right"
                  theme="whiteBlackText"
                  onClick={onOpen}
                  icon={<DownloadIcon height="14px" width="14px" color="#58595B" />}
                />
              </ChapterListWrapperStyle>
            </Panel>
          </div>
          <NextPrevLinkStyle to={nextLink}>
            <LinkNextInnerStyle>
              <NextText>{nextText}</NextText>
              <NextIconNavStyle color="#fff" width="15px" height="15px" />
            </LinkNextInnerStyle>
          </NextPrevLinkStyle>
        </GridStyle>
      </Container>
    </WrapperStyle>
  )
}

export default ChaptersList
