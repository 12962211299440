import React from 'react'
import {navigate} from 'gatsby'
import styled from 'styled-components'
import Container from 'los-design-system/components/Container'
import Grid from 'los-design-system/components/Grid'
import Button from 'los-design-system/components/Button'
import GetChapter from '../utils/GetChapter'

const Flex = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  border-top: 1px solid rgb(228, 228, 228);
  padding: 38px 15px 33px;

  @media (min-width: 992px) {
    &:last-child {
      border-left: 1px solid rgb(228, 228, 228);
    }

    &:first-child {
      border-left: 0;
    }
  }
`

const Chapter = styled('div')`
  font-family: Montserrat;
  font-weight: 500;
  font-style: italic;
  line-height: 1.88;
  text-align: center;
  font-size: 12px;
  color: rgb(128, 130, 133);
  margin-bottom: 33px;
`

const Title = styled('div')`
  font-family: Montserrat;
  font-weight: 500;
  font-style: italic;
  line-height: 1.88;
  text-align: center;
  font-size: 16px;
  color: rgb(0, 18, 23);
  margin-bottom: 48px;
`

interface ArticlePaginationProps {
  chapter: number
  posts: {
    fields: {
      slug: string
    }
    frontmatter: {
      title: string
      chapter: number
    }
  }[]
}

const ArticlePagination = ({posts, chapter}: ArticlePaginationProps) => {
  const index = chapter - 1
  const canGoBack = index !== 0
  const canGoForward = index < posts.length - 1

  return (
    <Container mt="64px">
      <Grid
        gridTemplateColumns={['1fr', null, null, canGoBack && canGoForward ? '1fr 1fr' : '1fr']}
        pb="167px"
      >
        {canGoBack && (
          <Flex>
            <Chapter>{GetChapter(posts[index - 1].frontmatter.chapter)}</Chapter>
            <Title>{posts[index - 1].frontmatter.title}</Title>
            <Button
              ariaLabel={posts[index - 1].frontmatter.title}
              text="Read Previous"
              size="semi"
              onClick={() => navigate(posts[index - 1].fields.slug)}
            />
          </Flex>
        )}
        {canGoForward && (
          <Flex>
            <Chapter>{GetChapter(posts[index + 1].frontmatter.chapter)}</Chapter>
            <Title>{posts[index + 1].frontmatter.title}</Title>
            <Button
              ariaLabel={posts[index + 1].frontmatter.title}
              text="Read Next"
              size="semi"
              onClick={() => navigate(posts[index + 1].fields.slug)}
            />
          </Flex>
        )}
      </Grid>
    </Container>
  )
}

export default ArticlePagination
