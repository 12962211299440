import React, {useContext} from 'react'
import {Link} from 'gatsby'
import Button from 'los-design-system/components/Button'
import Box from 'los-design-system/components/Box'
import GeneralNavigation, {NavItem} from 'los-design-system/components/Navigation/GeneralNavigation'
import GeneralResponsiveNavigation from 'los-design-system/components/Navigation/GeneralResponsiveNavigation'
import Context from '../context/modalContext'

interface NavigationProps {
  pathname: string
}

const Navigation = ({pathname}: NavigationProps) => {
  const {onOpen} = useContext(Context)

  const navItems = (
    <>
      <NavItem active={pathname === '/'}>
        <Link to="/">Introduction</Link>
      </NavItem>
      <NavItem>
        <Button ariaLabel="Get free ebook" size="semi" text="Get free ebook" onClick={onOpen} />
      </NavItem>
    </>
  )

  const responsiveNavItems = (
    <>
      <div>
        <Link to="/">Introduction</Link>
      </div>
      <div>
        <Button ariaLabel="Get free ebook" text="Get free ebook" onClick={onOpen} />
      </div>
    </>
  )

  return (
    <Box>
      <Box display={['block', null, 'none']}>
        <GeneralResponsiveNavigation
          openMenuItems={responsiveNavItems}
          responsiveHeader={<div />}
          search={<div />}
        />
      </Box>
      <Box display={['none', null, 'block']}>
        <GeneralNavigation navItems={navItems} />
      </Box>
    </Box>
  )
}

export default Navigation
