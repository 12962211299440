import React from 'react'
import styled, {css} from 'styled-components'
import {FieldRenderProps} from 'react-final-form'

export const CheckboxRadioWrapper = styled<any>('div')`
  position: relative;
  padding-bottom: 16px;

  ${({disabled}: {disabled: boolean}) =>
    disabled &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.4;
        background-color: #ffffff;
        z-index: 2;
      }
    `}

  ${({showErrors}: {showErrors: boolean}) =>
    showErrors &&
    css`
      padding-bottom: 32px;
    `}
`

export const CheckboxRadioLabelStyle = styled('label')`
  position: relative;
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  color: #58595b;
  margin: 0;
`

export const CheckboxRadioInputStyle = styled('input')`
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`

interface CheckboxBackgroundProps {
  checked?: boolean
}
const Background = styled('span')<CheckboxBackgroundProps>`
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #bcbec0;
  border-radius: 1px;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-collapse: separate;
  margin-right: 16px;

  &:before {
    content: '';
    transform: rotate(45deg);
    position: absolute;
    left: 4px;
    top: 1.14285714px;
    width: 5.71428571px;
    height: 10px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    opacity: 1;
  }

  ${({checked}) =>
    checked &&
    css`
      background: #00aeef;
      box-shadow: 0 2px 5px 0 rgba(0, 174, 239, 0.25);
      border-color: #00aeef;
      &:before {
        content: '';
        opacity: 1;
      }
    `}
`

const InputStyle = styled(CheckboxRadioInputStyle)`
  &:focus + ${Background} {
    outline: 1px solid #00aeef;
    border-color: #00aeef;
  }

  &:hover + ${Background} {
    border-color: #00aeef;
  }
`

interface CheckboxProps extends FieldRenderProps<any> {
  disabled?: boolean
  label?: string | React.ReactNode
  theme?: 'blue' | 'yellow' | 'green' | 'red'
  showErrors?: boolean
  inputKey?: string
  className?: string
}

const Text = styled('div')`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  color: #ee2f53;
  position: absolute;
  left: 0;
  right: 0;
  height: 26px;
  bottom: 0;
`

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  input,
  meta,
  inputKey = '',
  label = '',
  className = '',
  disabled = false,
  showErrors = true,
}: CheckboxProps) => {
  const error = meta.touched && (meta.error || meta.submitError)

  return (
    <CheckboxRadioWrapper className={className} disabled={disabled} showErrors={showErrors}>
      <CheckboxRadioLabelStyle htmlFor={inputKey}>
        <InputStyle
          data-testid="checkbox"
          {...input}
          type="checkbox"
          id={inputKey}
          disabled={disabled}
        />
        <Background checked={input.checked} />
        {label}
      </CheckboxRadioLabelStyle>
      {showErrors && <Text>{error}</Text>}
    </CheckboxRadioWrapper>
  )
}

export default Checkbox
